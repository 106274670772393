const SECONDS_PER_WEEK = 604800

const FEES = [
  {
    from: 1620205889 - SECONDS_PER_WEEK,
    to: 1620205889,
    feeAssets: "0.1603 BTC, 4.4701 ETH, 42.7548 BNB, 13,709.5913 BUSD, 6,134.1901 USDC, 8,134.1596 USDT",
    feeUsd: "79826.79"
  },
  {
    from: 1620786634 - SECONDS_PER_WEEK,
    to: 1620786634,
    feeAssets: "0.0412 BTC, 5.6775 ETH, 39.8377 BNB, 10,228.1919 BUSD, 11,095.6480 USDC, 1,163.4386 USDT",
    feeUsd: "75618.63"
  },
  {
    from: 1621393956 - SECONDS_PER_WEEK,
    to: 1621393956,
    feeAssets: "0.1596 BTC, 8.2261 ETH, 225.1073 BNB, 9,472.8827 BUSD, 66,029.0305 USDC, 15,171.2389 USDT",
    feeUsd: "226885.65"
  },
  {
    from: 1621999187 - SECONDS_PER_WEEK,
    to: 1621999187,
    feeAssets: "0.2624 BTC, 8.7960 ETH, 56.3157 BNB, 25,812.4605 BUSD, 19,202.5132 USDC, 3,233.8975 USDT",
    feeUsd: "103498.60"
  },
  {
    from: 1622594843 - SECONDS_PER_WEEK,
    to: 1622594843,
    feeAssets: "0.0434 BTC, 1.5276 ETH, 29.6398 BNB, 44,034.42733 BUSD, 957.2660 USDC, 6,259.4550 USDT",
    feeUsd: "67466.93"
  },
  {
    from: 1623204440 - SECONDS_PER_WEEK,
    to: 1623204440,
    feeAssets: "0.1210 BTC, 4.7566 ETH, 109.0878 BNB, 79,148.7061 BUSD, 404.5143 USDC, 26,713.8593 USDT",
    feeUsd: "158720.51"
  },
  {
    from: 1623806161 - SECONDS_PER_WEEK,
    to: 1623806161,
    feeAssets: "0.0963 BTC, 1.4782 ETH, 15.1441 BNB, 9,259.8002 BUSD, 444.7038 USDC, 5,332.7327 USDT",
    feeUsd: "27984.96"
  },
  {
    from: 1624416975 - SECONDS_PER_WEEK,
    to: 1624416975,
    feeAssets: "0.1862 BTC, 4.5722 ETH, 38.4881 BNB, 13,157.9102 BUSD, 681.9518 USDC, 20,719.0259 USDT",
    feeUsd: "61211.44"
  },
  {
    from: 1625020792 - SECONDS_PER_WEEK,
    to: 1625020792,
    feeAssets: "0.1994 BTC, 2.9843 ETH, 8.7167 BNB, 4,245.4470 BUSD, 713.8151 USDC, 6,716.4781 USDT",
    feeUsd: "27892.58"
  },
  {
    from: 1625625326 - SECONDS_PER_WEEK,
    to: 1625625326,
    feeAssets: "0.2035 BTC, 2.8036 ETH, 29.0733 BNB, 13,941.9031 BUSD, 4,239.1012 USDC, 1,858.4399 USDT",
    feeUsd: "42810.32"
  },
  {
    from: 1626224461 - SECONDS_PER_WEEK,
    to: 1626224461,
    feeAssets: "0.0525 BTC, 2.9409 ETH, 32.7671 BNB, 11,618.6892 BUSD, 534.9965 USDC, 385.8577 USDT",
    feeUsd: "29759.28"
  },
  {
    from: 1626839577 - SECONDS_PER_WEEK,
    to: 1626839577,
    feeAssets: "0.1188 BTC, 4.8821 ETH, 54.8723 BNB, 10,692.3461 BUSD, 1,349.0441 USDC, 5,857.4312 USDT",
    feeUsd: "45033.49"
  },
  {
    from: 1627440534 - SECONDS_PER_WEEK,
    to: 1627440534,
    feeAssets: "0.1433 BTC, 8.5198 ETH, 35.7903 BNB, 26,835.5473 BUSD, 7,395.7415 USDC, 4,244.9563 USDT",
    feeUsd: "75054.14"
  },
  {
    from: 1628047046 - SECONDS_PER_WEEK,
    to: 1628047046,
    feeAssets: "0.2869 BTC, 2.0402 ETH, 49.8049 BNB, 18,377.8680 BUSD, 4,445.9922 USDC, 200.8252 USDT",
    feeUsd: "55084.02"
  },
  {
    from: 1628648752 - SECONDS_PER_WEEK,
    to: 1628648752,
    feeAssets: "0.0989 BTC, 2.3187 ETH, 44.4530 BNB, 17,996.3197 BUSD, 4,631.1984 USDC, 2,021.0504 USDT",
    feeUsd: "53488.93"
  },
  {
    from: 1629252005 - SECONDS_PER_WEEK,
    to: 1629252005,
    feeAssets: "0.0710 BTC, 1.9880 ETH, 66.6524 BNB, 24,078.5095 BUSD, 8,738.5302 USDC, 846.4301 USDT",
    feeUsd: "69267.72"
  },
  {
    from: 1629862747 - SECONDS_PER_WEEK,
    to: 1629862747,
    feeAssets: "0.0943 BTC, 2.5074 ETH, 75.9118 BNB, 26,466.1170 BUSD, 5,297.8244 USDC, 1,178.5981 USDT",
    feeUsd: "82093.64"
  },
  {
    from: 1630477058 - SECONDS_PER_WEEK,
    to: 1630477058,
    feeAssets: "0.1088 BTC, 1.5657 ETH, 61.7673 BNB, 28,502.8294 BUSD, 2,708.0890 USDC, 906.6013 USDT",
    feeUsd: "71726.58"
  },
  {
    from: 1631676367 - SECONDS_PER_WEEK,
    to: 1631676367,
    feeAssets: "0.0714 BTC, 1.3515 ETH, 44.7685 BNB, 20,945.5699 BUSD, 1,762.2466 USDC, 326.7853 USDT",
    feeUsd: "49526.98"
  },
  {
    from: 1632277183 - SECONDS_PER_WEEK,
    to: 1632277183,
    feeAssets: "0.0355 BTC, 0.6284 ETH, 23.8790 BNB, 8,521.0771 BUSD, 1,290.8155 USDC, 4,684.6727 USDT",
    feeUsd: "26209.15"
  },
  {
    from: 1632884038 - SECONDS_PER_WEEK,
    to: 1632884038,
    feeAssets: "0.0812 BTC, 0.9295 ETH, 13.5524 BNB, 6,775.3254 BUSD, 1,035.9021 USDC, 738.7350 USDT",
    feeUsd: "19200.78"
  },
  {
    from: 1633505411 - SECONDS_PER_WEEK,
    to: 1633505411,
    feeAssets: "0.1364 BTC, 2.6310 ETH, 37.4189 BNB, 14,555.5591 BUSD, 2,297.9291 USDC, 1,168.7667 USDT",
    feeUsd: "50216.94"
  },
  {
    from: 1634092314 - SECONDS_PER_WEEK,
    to: 1634092314,
    feeAssets: "0.1519 BTC, 1.8827 ETH, 44.7711 BNB, 16,966.8949 BUSD, 3,139.5578 USDC, 975.0722 USDT",
    feeUsd: "56767.76"
  },
  {
    from: 1634700662 - SECONDS_PER_WEEK,
    to: 1634700662,
    feeAssets: "0.2351 BTC, 2.0654 ETH, 41.0738 BNB, 10,108.5995 BUSD, 2,019.2081 USDC, 14,027.7341 USDT",
    feeUsd: "69075.09"
  },
  {
    from: 1635299955 - SECONDS_PER_WEEK,
    to: 1635299955,
    feeAssets: "0.1528 BTC, 3.9552 ETH, 44.9960 BNB, 18,669.5354 BUSD, 1,643.1693 USDC, 13,919.8689 USDT",
    feeUsd: "81483.96"
  },
  {
    from: 1635912795 - SECONDS_PER_WEEK,
    to: 1635912795,
    feeAssets: "0.3079 BTC, 4.2574 ETH, 66.6275 BNB, 27,868.1904 BUSD, 3,939.2559 USDC, 3,574.5251 USDT",
    feeUsd: "111053.73"
  },
  {
    from: 1636513948 - SECONDS_PER_WEEK,
    to: 1636513948,
    feeAssets: "0.1409 BTC, 1.8987 ETH, 38.8351 BNB, 21,116.5806 BUSD, 1,040.3069 USDC, 530.5464 USDT",
    feeUsd: "65635.02"
  },
  {
    from: 1637115485 - SECONDS_PER_WEEK,
    to: 1637115485,
    feeAssets: "0.3525 BTC, 3.6708 ETH, 89.9938 BNB, 52,879.0187 BUSD, 4,925.3438 USDC, 2,472.5773 USDT",
    feeUsd: "148518.78"
  },
  {
    from: 1637726358 - SECONDS_PER_WEEK,
    to: 1637726358,
    feeAssets: "0.4032 BTC, 4.2958 ETH, 98.8242 BNB, 69,311.2413 BUSD, 6,134.6152 USDC, 1,398.9481 USDT",
    feeUsd: "175258.27"
  },
  {
    from: 1638328578 - SECONDS_PER_WEEK,
    to: 1638328578,
    feeAssets: "0.1870 BTC, 2.6341 ETH, 93.9686 BNB, 62,120.0798 BUSD, 2,846.6801 USDC, 929.3088 USDT",
    feeUsd: "148668.96"
  },
  {
    from: 1638929733 - SECONDS_PER_WEEK,
    to: 1638929733,
    feeAssets: "0.4714 BTC, 4.8094 ETH, 61.4740 BNB, 61,929.5263 BUSD, 2,314.5460 USDC, 175.1687 USDT",
    feeUsd: "144482.23"
  }
]

export function getFeeHistory() {
  return FEES.concat([]).reverse()
}
