import React, { useEffect } from 'react'
import useSWR from 'swr'
import {
	formatAmount,
	bigNumberify,
	USD_DECIMALS,
	CHAIN_ID,
	getExplorerUrl,
	formatDateTime,
} from '../../Helpers'

import './TradeHistory.css';

export default function TradeHistory(props) {
  const { 
  	account,
  	infoTokens,
  	getTokenInfo
  } = props
  const url = `https://gambit-server-staging.uc.r.appspot.com/actions?account=${account}`
  const { data: trades, mutate: updateTrades } = useSWR([url], {
    fetcher: (...args) => fetch(...args).then(res => res.json())
  })

  useEffect(() => {
    const interval = setInterval(() => {
      updateTrades(undefined, true)
    }, 10 * 1000)
    return () => clearInterval(interval);
  }, [updateTrades])

  const getMsg = (trade) => {
    const tradeData = trade.data
    const params = JSON.parse(tradeData.params)
    let defaultMsg = ""

    if (tradeData.action === "BuyUSDG") {
      const token = getTokenInfo(infoTokens, params.token, true)
      if (!token) {
        return defaultMsg
      }
      return `Swap ${formatAmount(params.tokenAmount, token.decimals, 4, true)} ${token.symbol} for ${formatAmount(params.usdgAmount, 18, 4, true)} USDG`
    }

    if (tradeData.action === "SellUSDG") {
      const token = getTokenInfo(infoTokens, params.token, true)
      if (!token) {
        return defaultMsg
      }
      return `Swap ${formatAmount(params.usdgAmount, 18, 4, true)} USDG for ${formatAmount(params.tokenAmount, token.decimals, 4, true)} ${token.symbol}`
    }

    if (tradeData.action === "Swap") {
      const tokenIn = getTokenInfo(infoTokens, params.tokenIn, true)
      const tokenOut = getTokenInfo(infoTokens, params.tokenOut, true)
      if (!tokenIn || !tokenOut) {
        return defaultMsg
      }
      return `Swap ${formatAmount(params.amountIn, tokenIn.decimals, 4, true)} ${tokenIn.symbol} for ${formatAmount(params.amountOut, tokenOut.decimals, 4, true)} ${tokenOut.symbol}`
    }

    if (tradeData.action === "IncreasePosition-Long" || tradeData.action === "IncreasePosition-Short") {
      const indexToken = getTokenInfo(infoTokens, params.indexToken, true)
      if (!indexToken) {
        return defaultMsg
      }
      if (bigNumberify(params.sizeDelta).eq(0)) {
        return `Deposit ${formatAmount(params.collateralDelta, USD_DECIMALS, 2, true)} USD into ${indexToken.symbol} ${params.isLong ? "Long" : "Short"}`
      }
      return `Increase ${indexToken.symbol} ${params.isLong ? "Long" : "Short"}, +${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD, ${indexToken.symbol} Price: ${formatAmount(params.price, USD_DECIMALS, 2, true)} USD`
    }

    if (tradeData.action === "DecreasePosition-Long" || tradeData.action === "DecreasePosition-Short") {
      const indexToken = getTokenInfo(infoTokens, params.indexToken, true)
      if (!indexToken) {
        return defaultMsg
      }
      if (bigNumberify(params.sizeDelta).eq(0)) {
        return `Withdraw ${formatAmount(params.collateralDelta, USD_DECIMALS, 2, true)} USD from ${indexToken.symbol} ${params.isLong ? "Long" : "Short"}`
      }
      return `Decrease ${indexToken.symbol} ${params.isLong ? "Long" : "Short"}, -${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD, ${indexToken.symbol} Price: ${formatAmount(params.price, USD_DECIMALS, 2, true)} USD`
    }

    if (tradeData.action === "LiquidatePosition-Long" || tradeData.action === "LiquidatePosition-Short") {
      const indexToken = getTokenInfo(infoTokens, params.indexToken, true)
      if (!indexToken) {
        return defaultMsg
      }
      return `Liquidated ${indexToken.symbol} ${params.isLong ? "Long" : "Short"}, ${formatAmount(params.size, USD_DECIMALS, 2, true)} USD, ${indexToken.symbol} Price: ${formatAmount(params.markPrice, USD_DECIMALS, 2, true)} USD`
    }

    return tradeData.action
  }

  return (
    <div className="TradeHistory">
    {(!trades || trades.length === 0) && <div className="TradeHistory-row border">
      No trades yet
    </div>
    }
    {(trades && trades.length > 0) && trades.slice(0, 10).map((trade, index) => {
      const tradeData = trade.data
      const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + tradeData.txhash
      let msg = getMsg(trade)
      return (
        <div className="TradeHistory-row border" key={index}>
          <div>
            <div className="muted TradeHistory-time">{formatDateTime(tradeData.timestamp)}</div>
            <a className="plain" href={txUrl} target="_blank" rel="noopener noreferrer">{msg}</a>
          </div>
        </div>
      )
    })}
    </div>
  )
}

