import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import cx from "classnames";
import { AreaChart, Area, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

import {
	USD_DECIMALS,
	SWAP,
	CHAIN_ID,
	getTokenInfo,
	formatAmount,
	formatDateTime,
	numberWithCommas
} from '../../Helpers'

import { getTokens } from '../../data/Tokens'

const DAY = "DAY"
const WEEK = "WEEK"
const MONTH = "MONTH"

const CHART_RANGE_OPTIONS = [DAY, WEEK, MONTH]

const PriceTooltip = ({ active, payload, label }) => {
  if (active && payload && payload[0] && payload[0].payload) {
    return (
      <div className="Exchange-price-tooltip">
        <div className="Exchange-price-time">{formatDateTime(payload[0].payload.time)}</div>
        <div>{numberWithCommas(payload[0].payload.chartValue.toFixed(2))} USD</div>
      </div>
    )
  }

  return null
}

function getChartToken(swapOption, fromToken, toToken) {
  if (!fromToken || !toToken) { return }

  if (swapOption !== SWAP) { return toToken }

  if (fromToken.isUsdg && toToken.isUsdg) { return getTokens(CHAIN_ID).find(t => t.isStable) }
  if (fromToken.isUsdg) { return toToken }
  if (toToken.isUsdg) { return fromToken }

  if (fromToken.isStable && toToken.isStable) { return toToken }
  if (fromToken.isStable) { return toToken }
  if (toToken.isStable) { return fromToken }

  return toToken
}

export default function ExchangeChart(props) {
  const { swapOption, fromTokenAddress, toTokenAddress, infoTokens } = props
  const [chartRangeOption, setChartRangeOption] = useState(DAY)
  const fromToken = getTokenInfo(infoTokens, fromTokenAddress)
  const toToken = getTokenInfo(infoTokens, toTokenAddress)
  const chartToken = getChartToken(swapOption, fromToken, toToken)
  const symbol = chartToken.symbol === "WBNB" ? "BNB" : chartToken.symbol
  const marketName = chartToken ? symbol + "_USD" : undefined

  let days = 1
  if (chartRangeOption === WEEK) {
    days = 7
  }
  if (chartRangeOption === MONTH) {
    days = 30
  }
  const url = `https://cors-300607.uc.r.appspot.com/price?market=${marketName}&days=${days}&chainId=56`
  const { data: prices, mutate: updatePrices } = useSWR([url], {
    fetcher: (...args) => fetch(...args).then(res => res.json())
  })

  let priceData = []
  let maxChartValue
  let minChartValue
  let hasPriceIncrease
  let priceDeltaPercentage
  const now = parseInt(Date.now() / 1000)
  let firstPrice
  let lastPrice
  if (prices && prices.data && prices.data.result && prices.data.result[0]) {
    const result = JSON.parse(JSON.stringify(prices.data.result[0].values))
    if (chartToken && chartToken.maxPrice) {
      result.push([now, formatAmount(chartToken.maxPrice, USD_DECIMALS, 2)])
    }
    let minValue = result.length === 0 ? 1000000 : parseFloat(result[0][1])
    let maxValue = 0
    for (let i = 0; i < result.length; i++) {
      const item = result[i]
      const chartValue = parseFloat(item[1])
      if (!isNaN(chartValue)) {
        if (chartValue > maxValue) {
          maxValue = chartValue
        }
        if (chartValue < minValue) {
          minValue = chartValue
        }
      }

      if (parseInt(item[0]) <= now) {
        priceData.push({
          time: item[0],
          chartValue,
          strValue: item[1],
          chartValueFormatted: numberWithCommas(chartValue)
        })
      }
    }

    if (priceData.length > 1) {
      firstPrice = priceData[0].chartValue
      lastPrice = priceData[priceData.length - 1].chartValue
      hasPriceIncrease = lastPrice > firstPrice
      const priceDelta = lastPrice > firstPrice ? (lastPrice - firstPrice) : (firstPrice - lastPrice)
      priceDeltaPercentage = (priceDelta / firstPrice) * 100
    }

    if (minValue && maxValue) {
      const range = maxValue - minValue
      maxChartValue = maxValue + range / 1.5
      minChartValue = minValue - range / 5
    }

    if (chartToken && chartToken.isStable) {
      maxChartValue = 1.5
      minChartValue = 0.5
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      updatePrices(undefined, true)
    }, 60 * 1000)
    return () => clearInterval(interval);
  }, [updatePrices])

  return (
    <div className="ExchangeChart border">
      <div className="ExchangeChart-top">
        <div className="ExchangeChart-top-inner">
          <div>
            <div className="ExchangeChart-title">
              {chartToken && `${chartToken.symbol} / USD`}
            </div>
            <div className="ExchangeChart-range-options">
              {CHART_RANGE_OPTIONS.map(option => <div key={option} className={cx("ExchangeChart-range-option", { active: option === chartRangeOption })} onClick={() => setChartRangeOption(option)}>
                {option}
              </div>)}
            </div>
          </div>
          <div className="ExchangeChart-main-price">
            <div>
              {(lastPrice) && <div className="ExchangeChart-dollar-sign">$</div>}
              {(lastPrice) && <div className="ExchangeChart-main-price-text">{numberWithCommas(lastPrice.toFixed(2))}</div>}
            </div>
            <div className={cx("ExchangeChart-price-delta", { positive: hasPriceIncrease, negative: !hasPriceIncrease })}>
              {priceDeltaPercentage !== undefined && `${hasPriceIncrease ? "+" : "-"}${priceDeltaPercentage.toFixed(2)}%`}
            </div>
          </div>
        </div>
      </div>
      <div className="ExchangeChart-container">
        <ResponsiveContainer>
          <AreaChart data={priceData} margin={{ top: 0, right: 0, left: 0, bottom: 0, }} >
            <defs>
              <linearGradient id="priceStrokeColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset="20%" stopColor="#c2489d" stopOpacity={1}/>
                <stop offset="95%" stopColor="#514cd9" stopOpacity={1}/>
              </linearGradient>
              <linearGradient id="priceFillColor" x1="0" y1="0" x2="0" y2="1" gradientTransform="rotate(0)">
                <stop offset="0%" stopColor="#e00092" stopOpacity={0.3}/>
                <stop offset="100%" stopColor="#4f00fa" stopOpacity={0.5}/>
              </linearGradient>
            </defs>
            <YAxis hide={true} domain={[minChartValue, maxChartValue]}/>
            <Tooltip content={ PriceTooltip } />
            <Area type="natural" dataKey="chartValue" stroke="url(#priceStrokeColor)" strokeWidth="2" fill="url(#priceFillColor)" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
